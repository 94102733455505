.h3{
    padding: 3px 0px;
}
h1, h3{
    padding: 3px 0px;
}
@media (max-width:420px){
    .termDiv{
        padding: 0px 20px !important;
    }
}