.firstCol a{
    text-decoration: none;
}

.footercontent{
    height: 250px;
}
.firstCol{
    display: flex;
    align-items: center;
    /* border: 2px solid green; */
}
.firstCol ul{
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.footerData{
    /* font-family: 'Open Sans'; */
    margin-top: 10px;
font-style: normal;
font-weight: 600;
font-size: 13px;
line-height: 19px;
letter-spacing: -0.015em;
color: #747373;
}
.footerThird{
    /* height: 70%; */
    /* height: 60%; */
    /* margin-bottom: 100px; */
    /* border: 2px solid black; */
    display: flex;
    align-items:flex-end;
    flex-direction: column;
    /* justify-content: space-around; */
}
.ThirdContent{
    /* border: 2px solid red; */
    display: flex;
    align-items: center;
    justify-content: end;
}
.footerList{
    /* font-family: 'Open Sans'; */
font-style: normal;
font-weight: 600;
font-size: 13px;
line-height: 19px;
letter-spacing: -0.015em;
color: #F45237;
overflow: hidden;
}
.footerlastLine{
    margin-top: 10px;
    text-align: center;
    font-weight: 600;
font-size: 13px;
color: #747373;
}
.social{
    display: flex;
    /* align-items: end; */
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    /* height: 70%; */
    /* border: 2px solid black; */
}
.socialContent{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}
.fContent{
    /* border: 2px solid black; */
    width: 100%;
    display: flex;
    /* align-items: center; */
    justify-content: space-around;
}
.fLine{
    width: 2px;
    height: 35px;
    background-color: gray;
}
/* #fcontactnumber{
    margin-top: 26px;
} */

@media only screen and (max-width: 768px) {
   
    .footercontent {
        /* height: 170px; */
    }
  }
  @media only screen and (max-width: 600px){
    .social {
        /* height: 17%; */
        height: auto;
    }
    .footerData{
        padding: 3px;
        text-align: center;
    }
  }

  @media (max-width:768px){
    .fLine{display: none;}
    .fContent{
        display: flex;
        flex-direction: column;
    }
  }

  @media (max-width:420px){
    .footercontent{display: none !important;}
  .mobileDIv{
    /* border: 2px solid black; */
    display: block !important;
  }
  .mobileDIvRow{
    /* border: 2px solid green; */
    display: flex !important;
    justify-content: space-between;
    margin-right: 4px;
    margin-left: -10px;
  }

  .MfirstCol{
    /* border: 2px solid red; */
    text-decoration: none;
  }
  .MfirstCol a{
    text-decoration: none;
  }
  .MfirstCol ul{
    margin: 10px;
    padding: 10px;
  }

  .MsecondCol{
    /* border: 2px solid green; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
}