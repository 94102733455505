.clientBox{
    /* border: 2px solid green; */
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding-top: 50px;
}
.cBox{
    /* width: 40%; */
    height: 350px;
}
.cBox img{
    width: 100%;
    height: 100%;
}
.clientText p{
color: #FFF;
margin: 30px auto ;
width: 90%;
text-align: center;
font-size: 24px;
font-weight: 700;
}
.clientText2 p{
    color: white;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    margin: 10px auto;
    width: 70%;
}

@media (max-width:600px){
    .cBox{
        width: 80%;
        height: 350px;
    }
}
@media (max-width:420px){
    .cBox{
        width: 80%;
        height: 250px;
    }
    .clientText p{
        color: #FFF;
        margin: 30px auto ;
        width: 85%;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
    }
    .clientText2 p{
        color: white;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        margin: 10px auto;
        width: 85%;
    }
}