*{
    overflow-x: hidden;
}
p{
    padding: 0;
    margin: 0;
}

.mainColor{
    background: "linear-gradient(104.31deg, #F95B30 5.98%, #D81C65 93.2%)",
}