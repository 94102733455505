.rkp-image {
  background-image: url("../../asset/img/banner/myBanner1.png");
  width: 100%;
  height: auto;
  /* height: 100vh; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* object-fit: contain; */
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
}

.rkpsecond{
  /* border: 2px solid black; */
  width:50%;
  margin-left: 150px !important;
  margin-top: 250px !important;
}

.rkp-text {
  font-style: normal;
  font-weight: 800;
  font-size: 44px;
  /* line-height: 87px; */
  text-align: left;
  text-transform: uppercase;
  color: #ffffff;
}
.rkp-text2 {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  padding: 3px 0px;
  /* line-height: 37px; */
  text-align: left;
  /* text-transform: uppercase; */
  color: #ffffff;
  width:80%;
  /* padding: 0px 10px; */
}
.lastLine{
  color: white;
  text-align: center;
  font-size: 30px;
  padding: 3px 0px;
}

#bannerArrow{
  display: block;
  width: 7%;
  margin: auto;
}
#bannerArrow2{
  display: none;
}

@media (max-width:430px){
  .rkpsecond{
    margin: 0px !important;
    display: none !important;
    width: 80% !important;
    margin:auto;
    margin-top: 70px !important;
    margin-left: 70px !important;
  /* border: 2px solid black; */
  }
  .rkp-image {
    background-image: url("../../asset/img/banner/newBanner.png");
    width: 100%;
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* object-fit: contain; */
  }
  .rkp-image{
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .rkp-text {
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 37px;
    text-align: left;
    text-transform: uppercase;
    color: #ffffff;
    padding: 0px 10px;
  }
  .rkp-text2 {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 37px;
    text-align: left;
    text-transform: uppercase;
    color: #ffffff;
    padding: 0px 10px;
  }
  .lastLine{
    position: absolute;
    bottom: 50px;
    left: 0%;
    width: 100%;
    color: white;
    text-align: center;
    font-size: 15px;
    /* display: none !important; */
    /* margin-top: 10px; */
  }
  #bannerArrow{display: none !important;}
  #bannerArrow2{
    display: none;
    position: absolute;
    bottom: 0px;
    left: 42%;
    width: 15%;
  }
}

@media (min-width:600px) and (max-width:799px){
  .rkp-text {
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    /* line-height: 37px; */
    text-align: left;
    text-transform: uppercase;
    color: #ffffff;
    padding: 3px 10px;
  }
  .rkp-text2 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    /* line-height: 37px; */
    text-align: left;
    text-transform: uppercase;
    color: #ffffff;
    padding: 0px 10px;
  }
  .rkpsecond{
    margin: 0px !important;
    /* display: none !important; */
    width: 50% !important;
    margin-top: 70px !important;
    margin-left: 40px !important;
    margin-bottom: 50px !important;
  /* border: 2px solid black; */
  }
  .rkp-image {
    width: 100%;
    height: auto;
    /* height: 80vh; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .lastLine{
    font-size: 18px;
  }
}
@media (min-width:431px) and (max-width:599px){
  .rkp-text {
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 37px;
    text-align: left;
    text-transform: uppercase;
    color: #ffffff;
    padding: 3px 5px;
  }
  .rkp-text2 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 37px;
    text-align: left;
    text-transform: uppercase;
    color: #ffffff;
    padding: 0px 10px;
  }
  .lastLine{
    width: 100%;
    color: white;
    text-align: center;
    font-size: 20px;
    /* margin-top: 10px; */
  }
  .rkpsecond{
    margin: 0px !important;
    /* display: none !important; */
    width: 50% !important;
    margin-top: 70px !important;
    margin-left: 40px !important;
    margin-bottom: 50px !important;
  /* border: 2px solid black; */
  }
}