h1, h2,h3,h4,h5,h6{
    padding: 3px 0px;
}
#respBlog p img{
    width: 100% !important;
    height: 100% !important;
}

.authanddate2 {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    margin-top: 20px;
    margin-bottom: 30px;
}
.mblogMaindiv{
    /* border: 1px solid black; */
    padding: 0px 100px;
    margin-top: 50px;
}

.blogSingleImg2 {
    width: 50px;
    height: 50px;
    /* border: 1px solid black; */
    border-radius: 50%;
    margin-right: 10px;
}

.blogSingleImg2 img {
    width: 100%;
    height: 100%;
}

.authur2 {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin-right: 30px;
}
.mblogMainPicDiv {
    width: 100%;
    margin-top: 50px;
    /* padding: 0px 90px; */
    /* height: 100vh; */
}

.mblogMainPicDiv img {
    width: 100%;
}

.mblogtext{
    /* border: 2px solid black; */
    margin-top: 40px;
    font-size: 20px;
}

.mblogheading h6{
    color: #F95B30;
font-size: 24px !important;
font-style: normal;
font-weight: 600;
padding: 3px 0px;
margin-top: 20px;
}

.mblogtext2{
    /* border: 2px solid black; */
    margin-top: 10px;
    font-size: 20px;
}
.mblogslider{
    /* border: 2px solid black; */
    margin-top: 50px;
}

.simcol{
    border: 2px solid #E8E8EA;
    border-radius: 8px;
    padding: 0px 10px;
    margin: 5px;
}
.simimg{
    /* border: 1px solid green; */
    max-height: 200px !important;
}

@media (max-width:500px){
    .mblogMaindiv{
        /* border: 1px solid black; */
        padding: 0px 10px;
        margin-top: 50px;
    }
    .simimg{
        /* border: 1px solid green; */
        max-height: auto !important;
    }
}