.cDetailmainDiv{
    /* border: 2px solid black; */
    padding-top: 50px;
}

.cDetailmainDiv h1{
    color: #FFF;
text-align: center;
font-size: 32px;
font-weight: 700;
text-transform: uppercase;
}
.cDetailBox{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* border: 2px solid green; */
    margin-top: 40px;
}
.cDetailBox input{
    padding: 10px;
    width: 40%;
    border-radius: 5px;
    border: none;
    outline: none;
}
.cDetailBox button{
    color: #F95B30;
   border: none;
   padding: 10px 20px;
   border-radius: 5px;
    margin: 30px 0px;
    font-weight: 700;
}

.cDetailBox p{
    color: #F95B30;
    width: 100% !important;
    text-align: center;
    font-size: 18px;
    font-weight: 800;
    text-transform: uppercase;
    animation: animate_text 10s linear infinite;
    /* border: 2px solid black; */
    margin: 0px 50px;
    white-space: nowrap;
    overflow-x: visible;
}
.scrollDiv{
    background-color: white;
    display: flex;
    width: 100%;
    padding: 10px;
}
@keyframes animate_text {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      transform: translate3d(-100%, 0, 0);
    }
}

@media (max-width:430px){
    .cDetailmainDiv h1{
        color: #FFF;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 3px 10px;
    }
    .cDetailBox{
        margin-top: 16px;
    }
    .cDetailBox input{
        padding: 10px;
        width: 90%;
        border-radius: 5px;
        border: none;
        outline: none;
    }
    .cDetailBox button{
        color: #F95B30;
       border: none;
       padding: 10px 20px;
       border-radius: 5px;
        margin: 20px 0px;
        font-weight: 700;
    }
    .cDetailBox p{
        /* border: 2px solid black; */
        width: auto;
        margin: 0px 30px;
        white-space: nowrap;
    overflow-x: visible;
    font-size: 13px;
    font-weight: 600;
    }
}

@media (min-width:769px) and (max-width:1000px){
    .cDetailmainDiv h1{
        color: #FFF;
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 3px 10px;
    }
    .cDetailBox input{
        padding: 10px;
        width: 70%;
        border-radius: 5px;
        border: none;
        outline: none;
    }
}