.findMainDiv {
    /* border: 2px solid black; */
    padding: 50px 0px;
}

.findmain {
    width: 80%;
    margin: 10px auto;
    background-color: white;
    padding: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.findmain h2 {
    padding: 3px 0px;
    color: #F95B30;
    font-size: 24px;
    font-weight: 700;
}

.FindFirst {
    /* border: 2px solid black; */
    width: 30%;
}

.FindSecond {
    margin: 0px 40px;
    /* border: 2px solid yellow; */
    width: 60%;
    font-size: 20px;
}

.FindThree{
    /* border: 2px solid green; */
    width: 30%;
    text-align: center;
}

.findmain .FindThree h2 {
    padding: 3px 0px;
    color: #DF2662;
    font-size: 20px;
    font-weight: 700;
}

@media (min-width:1001px){
    .findmain {
        width: 80%;
        min-height: 200px !important;
        margin: 10px auto;
        background-color: white;
        padding: 45px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

@media (max-width:1000px){
    .findMainDiv {
        /* border: 2px solid black; */
        padding-top: 0px !important;
    }
    .findmain {
        width: 90%;
        /* min-height: 150px !important; */
        margin: 10px auto;
        background-color: white;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }
    .FindFirst {
        /* border: 2px solid black; */
        width: 100%;
    }
    
    .FindSecond {
        margin: 0px 40px;
        /* border: 2px solid yellow; */
        width: 100%;
        font-size: 12px;
    }
    
    .FindThree{
        /* border: 2px solid green; */
        margin-top: 10px;
        width: 100%;
        text-align: right;
    }
    .findmain h2 {
        padding: 3px 0px;
        color: #F95B30;
        font-size: 15px;
        font-weight: 700;
    }
    .findmain .FindThree h2 {
        padding: 3px 0px;
        color: #DF2662;
        font-size: 13px;
        font-weight: 700;
    }
}