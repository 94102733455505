.card {
    border-radius: 0px !important;
    margin: 20px 0px;
    border: none;
}

.cardT {
    height: 80px !important;
    /* border: 1px solid black; */
}

.imgtopp {
    /* border-radius: 0px !important; */
    /* border: 1px solid black; */
}

a {
    text-decoration: none;
}

.blogHeading {
    padding: 3px 0px;
    text-align: center;
    color: #F95B30;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    margin: 50px 0px;
    /* 75% */
}


.blogMainPicDiv {
    width: 100%;
    height: auto;
    padding: 0px 160px !important;
    /* border: 2px solid black; */
    /* height: 80vh; */
}
.blogMainPicDiv img{
    width: 100%;
    height: 50vh;
    object-fit: cover;
}


.bloginnerContent{
    border: 2px solid #E8E8EA;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top: none;
    padding-top: 20px;
    padding-left: 20px;
    /* margin-top: 10px; */
}

/* .blogMainPicDiv img {
    width: 100%;
} */

.blogsRow {
    margin: 80px 0px;
    display: flex;
    /* justify-content: space-between; */
    /* border: 2px solid red; */
}

.card-title {
    /* border: 1px solid green; */
    font-size: 20px !important;
    text-align: left;
    color: black;
    font-weight: 700;
}

.blogSingleImg {
    width: 50px;
    height: 50px;
    /* border: 1px solid black; */
    border-radius: 50%;
    margin-right: 10px;
}

.blogSingleImg img {
    width: 100%;
    height: 100%;
}

.authur {
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.authanddate {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cathead {
    /* color: white; */
    color: white;
    font-size: 14px !important;
}
.cathead2{
    color: #F95B30;
    font-size: 14px !important;
}

.catbody {
    padding: 10px !important;
    /* border: 2px solid green; */
    border: none !important;
}

.catmaincard {
    width: 15rem !important;
    /* border: 2px solid #F95B30; */
    background-color: #F95B30;
    color: white !important;
    border-radius: 5px !important;
}

.catmaincard2 {
    width: 15rem !important;
    border: 2px solid #E8E8EA;
    border-radius: 5px !important;
}

.catmainimg {
    height: 150px !important;
}

.categoryDivv {
    /* border: 2px solid black; */
    margin: 50px 0px;
    padding: 0px 0px 0px 90px;
}
.delecol{
    border: 2px solid #E8E8EA;
    border-radius: 8px !important;
    width: 30%;
    margin: 10px;
}
.badge{
    background-color: #F95B30 !important;
}
/* .allblogs{
    border: 2px solid black;
    padding: 0px 80px;
} */

.catDesk{
    /* border: 2px solid black; */
    margin: 0px 0px;
    display: grid;
    /* padding: 0px 50px; */
    padding-left: 50px;
    grid-template-columns: repeat(auto-fill,minmax(260px, 1fr));
    grid-template-rows:1fr;
    grid-auto-rows: 0;
    justify-content: space-between;  
    overflow-y: hidden; 
}

.catDesk2{
    /* border: 2px solid red; */
    margin: 0px 0px;
    display: grid;
    /* padding: 0px 50px; */
    padding-left: 50px;
    grid-template-columns: repeat(auto-fill,minmax(260px, 1fr));
    grid-template-rows:1fr;
    /* grid-auto-rows: 0; */
    justify-content: space-between;   
}

#catshowbtn{
    color: #F95B30;
    font-weight: 600;
    /* float: right; */
    /* clear: both; */
    margin-right: 50px;
    margin-bottom: 80px !important;
    text-decoration: underline;
    /* border: 2px solid black; */
    display: block;
    text-align: right;
}


@media (min-width:801px) and (max-width:998px) {
    .delecol{
        width: auto !important;
    }
}

@media (min-width:768px) and (max-width:840px) {
    .catDesk{
        /* border: 2px solid black; */
        margin: 40px 0px;
        display: grid;
        /* padding: 0px 50px; */
        padding-left: 10px !important;
        /* grid-template-columns: repeat(auto-fill,minmax(180px, 1fr)); */
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows:1fr;
        grid-auto-rows: 0;
        justify-content: space-between;  
        overflow-y: hidden; 
    }
    .catDesk2{
        /* border: 2px solid black; */
        margin: 40px 0px;
        display: grid;
        /* padding: 0px 50px; */
        padding-left: 10px !important;
        /* grid-template-columns: repeat(auto-fill,minmax(180px, 1fr)); */
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows:1fr;
        /* grid-auto-rows: 0; */
        justify-content: space-between;  
        overflow-y: hidden; 
    }
    .blogSingleImg{
        display: none !important;
    }
    .blogDate{
        font-size: 13px;
    }
    #authorP{
        /* background-color: red; */
        font-size: 18px !important;
    }
    .cardT{
        font-size: 16px !important;
    }
    .authanddate {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items:flex-start;
    }
    .blogDate {
        font-size: 18px;
        margin-top: 5px;
    }
}

@media (min-width:431px) and (max-width:600px) {
    .blogMainPicDiv {
        padding: 0px 40px !important;
    }
    .delecol {
        width: 45%;
        margin: 5px;
    }
    .cardT{
        font-size: 16px !important;
    }
}

@media (min-width:601px) and (max-width:767px) {
    .delecol {
        width: 45%;
        margin: 5px;
    }
}

@media (max-width:430px) {
    /* .cardT{
        height: auto !important;
    } */
    .blogMainPicDiv {
        width: 100%;
        padding: 0px 20px !important;
        /* height: 60vh; */
    }

    .blogHeading {
        margin: 30px 0px;
        font-size: 38px;
        /* 75% */
    }

    .blogsRow {
        /* border: 2px solid black; */
        display: flex;
        justify-content: space-between;
        /* align-items: center; */
        flex-wrap: wrap;
        margin: 30px 0px;
        /* padding: 0px 20px; */
    }

    .card {
        width: auto !important;
        /* width: 8rem !important; */
        margin: 20px 0px;
        border: none;
    }
    .delecol {
        /* border: 1px solid red; */
        width: 47%;
        /* height: auto !important; */
        margin: 5px;
    }

    .card-body {
        padding: 20px 0px !important;
    }

    .blogSingleImg {
        width: 30px;
        height: 30px;
        /* border: 1px solid black; */
        border-radius: 50%;
        margin-right: 10px;
        display: none !important;
    }

    .blogSingleImg img {
        width: 100%;
        height: 100%;
    }

    .catmaincard {
        width: 15rem !important;
        margin: auto;
    }

    .catmaincard2 {
        width: 15rem !important;
        margin: auto;
    }

    .categoryDivv {
        /* border: 2px solid black; */
        margin: 50px 0px;
        padding: 0px 0px 0px 0px;
    }
    .cardT{
        font-size: 13px !important;
        /* height: 65px !important; */
        height: auto !important;
    }
    .authanddate {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        /* border: 2px solid red; */
    }
    .authanddate p{
        font-size: 10px;
        font-weight: 500;
    }
    .blogDate{
        /* margin-left: -20px; */
        width: 100%;
        font-size: 15px;
    }
    .authur {
        display: block !important;
        /* display: flex;
        align-items: center; */
        width: 100%;
        margin-top: 10px;
    }
    .authur p{
        /* text-align: left !important; */
        font-size: 15px;
    }
    .categoryDivv{
        display: block !important;
    }
    #catshowbtn{display: none;}
    .catDesk{display: none;}
    .catDesk2{display: none;}
}

@media (max-width:357px) {
    .delecol {
        width: 90%;
        margin: 10px auto;
    }
}