.cardMainDiv{
    /* border: 2px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0px ;
    gap: 50px;
}

.outerCard{
    border: 2px solid white;
    border-radius: 10px;
    padding: 10px;
    width: 320px !important;
    height: auto;
    /* margin: auto; */
}

.cardBox{
    /* width: 300px !important; */
    width: 60%;
    margin: auto;
    /* height: 300px; */
}
.cardBox2{
    /* width: 300px !important; */
    width: 73%;
    margin: auto;
    /* height: 300px; */
}
.cardBox img{
    width: 100%;
    /* height: 100%; */
}
#cImg2{
    width: 100%;
    /* border: 2px solid black; */
}
.cardText h5{
    padding: 3px 0px;
    color: white;
    margin: 10px 0px;
    height: 70px;
}
.cardPara p{
    color: white;
    margin-bottom: 10px;
    font-size: 13px;
}
/* .outerDiv{
    width: 50%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
} */

.cardmobilecontainer{
    width: 100%;
    margin: auto !important;
    padding: 50px 0px;
}
.carousel-control-prev-icon {
    display: none !important;
}
.carousel-control-next-icon {
   display: none !important;
}

@media (max-width:730px){
    .cardmobilecontainer{
        display: block!important;
    }
    .cardMainDiv{
        display: none !important;
    }
    .outerCard {
        border: 2px solid white;
        border-radius: 10px;
        padding: 10px;
        width: 320px;
        height: auto;
        margin: auto !important;
    }
    .cardBox{
        /* width: 50% !important; */
        margin: auto !important;
    }
    .cardBox2{
        width: 73% !important;
        margin: auto !important;
    }
    .cardBox img{
        width: 100% !important;
        margin: auto !important;
    }
    .cardBox2 img{
        width: 100% !important;
        margin: auto !important;
    }
}
@media (max-width:330px){
    .outerCard {
        border: 2px solid white;
        border-radius: 10px;
        padding: 10px;
        width: 280px !important;
        height: auto;
        margin: auto !important;
    }
    .cardText h5{
        padding: 3px 0px;
        height: 60px !important;
    }
}