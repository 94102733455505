.trainerMainDiv{
    /* border: 2px solid black; */
    padding: 50px 0px;
}

.tMain{
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid black; */
}
.tMain2{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
}

.tFirst{
    width: 500px;
    height: 300px;
    /* border: 2px solid green; */
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: center;
}
.tSecond{
    width: 500px;
    height: 400px;
    /* border: 2px solid yellow; */
    padding: 10px;
}
.tFirst p{
    color: #FAFF00;
font-size: 18px;
font-weight: 400;
text-transform: uppercase;
/* text-align: right !important; */
width: 100%;
/* border: 1px solid black; */
}

.tFirst h5{
    color: #FFF;
font-size: 30px;
font-weight: 600;
padding: 3px 0px;
width: 100%;
}

.tFirst h6{
    color: white;
font-size: 20px;
font-weight: 400;
padding: 3px 0px;
}
.tSecond img{
    /* border: 2px solid black; */
    width: 100%;
    height: 100%;
    /* border-radius: 50%; */
}

@media (max-width:430px){
    .tSecond{
        width: 350px;
        height: 150px;
        /* border: 2px solid yellow; */
        padding: 10px;
        margin-right: 0px !important;
    }
    .tSecond img{
        width: 100%;
        height: 100%;
        /* border: 2px solid black; */
        /* border-radius: 50%; */
    }
    .tFirst p{
        color: #FAFF00;
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
    }
    
    .tFirst h5{
        color: #FFF;
    font-size: 15px;
    font-weight: 600;
    padding: 3px 0px;
    }
    
    .tFirst h6{
        color: white;
    font-size: 10px;
    font-weight: 400;
    padding: 3px 0px;
    }
    .tFirst{
        width: 500px;
        height: 200px;
        /* border: 2px solid green; */
        display: flex;
        flex-direction: column;
        align-items:flex-start;
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (min-width:431px) and (max-width:768px){
    .tSecond{
        width: 150px;
        height: 150px;
        /* border: 2px solid yellow; */
        padding: 10px;
        margin-right: 0px !important;
    }
    .tSecond img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
    .tFirst p{
        color: #FAFF00;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    }
    
    .tFirst h5{
        color: #FFF;
    font-size: 25px;
    font-weight: 600;
    padding: 3px 0px;
    }
    
    .tFirst h6{
        color: white;
    font-size: 18px;
    font-weight: 400;
    padding: 3px 0px;
    }
    .tFirst{
        width: 500px;
        height: 200px;
        /* border: 2px solid green; */
        display: flex;
        flex-direction: column;
        align-items:flex-start;
        justify-content: center;
        padding-left: 10px;
    }
}

@media (min-width:769px) and (max-width:1000px){
    .tSecond{
        width: 200px;
        height: 200px;
        /* border: 2px solid yellow; */
        padding: 10px;
        margin-right: 0px !important;
    }
    .tSecond img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
    .tFirst p{
        color: #FAFF00;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    }
    
    .tFirst h5{
        color: #FFF;
    font-size: 25px;
    font-weight: 600;
    padding: 3px 0px;
    }
    
    .tFirst h6{
        color: white;
    font-size: 18px;
    font-weight: 400;
    padding: 3px 0px;
    }
    .tFirst{
        width: 500px;
        height: 200px;
        /* border: 2px solid green; */
        display: flex;
        flex-direction: column;
        align-items:flex-start;
        justify-content: center;
        padding-left: 10px;
    }
}